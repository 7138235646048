import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';
import { Link } from 'react-router-dom';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { receiveTickets } from '@ttstr/actions';
import { Container, ContentrArea, LoadingSpinner, useIntl, Masonry } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const TicketListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  return (
    <article className="pb-5 ticket-listing mb-5">
      {/* <Container fluid className='ticket-header-image' /> */}
      {/* <div className="header-image">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img src={require('./assets/images/header-hund.png')} className="img-fluid w-100" alt="" />
            </div>
          </div>
        </div>
      </div> */}

      <h1 className="text-center pt-5 pb-3">{t(`TICKETLISTING.TITLE`)}</h1>

      <Container className="listing-filter-buttons-container mb-5">
        <h5>FILTERN:</h5>
        <Row className="mb-2 d-flex justify-items-center justify-content-center listing-buttons-container">
          <Col className="mt-3 col-12">
            <Link to="/tickets" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
              {'Alle Termine'}
            </Link>
          </Col>
          <Col className="mt-3 col-12 col-md-6">
            <Link to="/tickets-stueba" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
              {'DEINE FREUNDE UND STÜBAPhilharmonie 2025'}
            </Link>
          </Col>
          <Col className="mt-3 col-12 col-md-6">
            <Link to="/tickets?categoryId=19" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
              {'ALLE IN DIE HALLE 2025'}
            </Link>
          </Col>
        </Row>
      </Container>

      <Container className="list-page">
        <Helmet>
          <title>{t(`TICKETLISTING.TITLE`)}</title>
          <body className="ticket-listing-page" />
        </Helmet>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.TICKETS`)} />
        ) : tickets.length === 0 ? ( // if no tickets
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show tickets
          <Masonry id="ticket-listing" products={tickets} type="list" showCity showLocation showSubtitle showTags />
        )}
      </Container>

      <Container>
        <ContentrArea id="after-ticket-listing" />
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(TicketListing);
