import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';
import { Link } from 'react-router-dom';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { receiveTickets } from '@ttstr/actions';
import { Container, LoadingSpinner, useIntl, Masonry } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const TicketListingHidden: React.FC = () => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  return (
    <article className="pb-5 ticket-listing mb-5 s">
      {/* <Container fluid className='ticket-header-image' /> */}
      {/* <Container fluid className="header-image" /> */}

      <div className="container">
        <img
          src={require('./assets/images/dfot_head.png')}
          alt="DEINE FREUNDE – STÜBA PHILHARMONIE"
          className="img-fluid mt-5 mb-4"
        />
      </div>

      <Helmet>
        <title>{t(`TICKETLISTING.TITLE`)}</title>
        <body className="ticket-listing-page stueba" />
      </Helmet>

      <h1 className="text-center mb-4 d-none">{t(`TICKETLISTING.TITLE`)}</h1>

      <Container className="listing-filter-buttons-container mb-5">
        <h5>FILTERN:</h5>
        <Row className="mb-2 d-flex justify-items-center justify-content-center listing-buttons-container">
          <Col className="mt-3 col-12">
            <Link to="/tickets" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
              {'Alle Termine'}
            </Link>
          </Col>
          <Col className="mt-3 col-12 col-md-6">
            <Link to="/tickets-stueba" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
              {'DEINE FREUNDE UND STÜBAPhilharmonie 2025'}
            </Link>
          </Col>
          <Col className="mt-3 col-12 col-md-6">
            <Link to="/tickets?categoryId=19" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
              {'ALLE IN DIE HALLE 2025'}
            </Link>
          </Col>
        </Row>
      </Container>

      <Container className="list-page">
        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.TICKETS`)} />
        ) : tickets.length === 0 ? ( // if no tickets
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show tickets
          <Masonry
            id="ticket-listing"
            products={tickets.filter((t) => t.category_ids.includes(21))}
            type="list"
            showCity
            showLocation
            showTags
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(TicketListingHidden);
