import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { ContentrArea } from '@ttstr/components';

const Sozialtickets: React.FC = () => {
  const { t } = useTranslation();

  return (
    <article>
      <Container className="list-page my-5">
        <Helmet>
          <title>FAQ - SOZIALTICKETS</title>
          <body className="sozialticket-page" />
        </Helmet>
        <div className="container-fluid ">
          <h1 className="mb-5">FAQ - SOZIALTICKETS</h1>
          <ContentrArea id="page-content-sozialticket" />
        </div>
      </Container>
    </article>
  );
};

export default React.memo(Sozialtickets);
